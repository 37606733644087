import { Provision } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const ApprovedProvisionEntity: WebEntity<Provision> = {
  name: 'provision',
  endpoint: 'provision/approvedProvision',
  referenceColumn: 'provision',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text'
    },
    {
      header: 'Correlativo',
      accessorKey: 'correlative',
      columnType: 'text'
    },
    {
      header: 'Grupo Ledgers',
      accessorKey: 'ledgerGroup',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Sociedad',
      accessorKey: 'society',
      columnType: 'text'
    },
    {
      header: 'Clase de Documento',
      accessorKey: 'documentType',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Referencia',
      accessorKey: 'reference',
      columnType: 'text',
      Cell: () => '',
      valueToExport: () => ''
    },
    {
      header: 'Fecha de Documento',
      accessorKey: 'documentDate',
      columnType: 'text',
      size: 250,
      Cell: ({ row }) => dateFormatFn(row?.original?.documentDate, 'DD/MM/YYYY', true),
      valueToExport: (row) =>
        dateFormatFn(row.documentDate, 'DD/MM/YYYY', true).replaceAll('/', '.')
    },
    {
      header: 'Fecha de Contabilizacion',
      accessorKey: 'accountingDate',
      columnType: 'text',
      size: 300,
      Cell: ({ row }) => dateFormatFn(row?.original?.accountingDate, 'DD/MM/YYYY', true),
      valueToExport: (row) =>
        dateFormatFn(row.accountingDate, 'DD/MM/YYYY', true).replaceAll('/', '.')
    },
    {
      header: 'Moneda',
      accessorKey: 'coinDescription',
      columnType: 'text'
    },
    {
      header: 'Fecha Conversion',
      accessorKey: 'dateConversion',
      columnType: 'text'
    },
    {
      header: 'Tipo de Cambio',
      accessorKey: 'changeType',
      columnType: 'text'
    },
    {
      header: 'Texto Cabecera Docuemnto',
      accessorKey: 'textDocument',
      columnType: 'text',
      size: 300
    },
    {
      header: 'Referencia Documento',
      accessorKey: 'referenceDocument',
      columnType: 'text'
    },
    {
      header: 'Tipo de cuenta',
      accessorKey: 'typeAccount',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Numero de cuenta',
      accessorKey: 'accountSap',
      columnType: 'text',
      size: 220
    },
    {
      header: 'Importe MDA',
      accessorKey: 'amountMd',
      columnType: 'numeric',
      Cell: ({ row }) => formatStringNumber(row?.original?.amountMd)
    },
    {
      header: 'Asignacion',
      accessorKey: 'assignment',
      columnType: 'text'
    },
    {
      header: 'Importe Impuesto',
      accessorKey: 'taxAmount',
      columnType: 'text'
    },
    {
      header: 'Indicador Impuesto',
      accessorKey: 'taxindicator',
      columnType: 'text'
    },
    {
      header: 'Texto Posicion',
      accessorKey: 'text',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Condicion de Pago',
      accessorKey: 'paymentCondition',
      columnType: 'text'
    },
    {
      header: 'Via de Pago',
      accessorKey: 'paymentMethod',
      columnType: 'text'
    },
    {
      header: 'Referencia Factura',
      accessorKey: 'factureReference',
      columnType: 'text'
    },
    {
      header: 'Ejercicio Ref. Factura',
      accessorKey: 'invoiceExercise',
      columnType: 'text'
    },
    {
      header: 'Posicion Ref. Factura',
      accessorKey: 'positionRefFacture',
      columnType: 'text'
    },
    {
      header: 'Centro Beneficio',
      accessorKey: 'centroBeneficio',
      columnType: 'text',
      size: 210
    },
    {
      header: 'Referencia 1',
      accessorKey: 'referenceOne',
      columnType: 'text'
    },
    {
      header: 'Referencia 2',
      accessorKey: 'referencetwo',
      columnType: 'text'
    },
    {
      header: 'Referencia 3',
      accessorKey: 'referencethree',
      columnType: 'text'
    },
    {
      header: 'Clase Cuenta',
      accessorKey: 'accountClass',
      columnType: 'text'
    },
    {
      header: 'Fecha de Tesoseria',
      accessorKey: 'treasuryDate',
      columnType: 'text'
    },
    {
      header: 'Centro de coste',
      accessorKey: 'centroCoste',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Elemento PEP',
      accessorKey: 'elementPep',
      columnType: 'text'
    },
    {
      header: 'Numero De Orden',
      accessorKey: 'orderNumber',
      columnType: 'text'
    },
    {
      header: 'Sociedad GL',
      accessorKey: 'societyGl',
      columnType: 'text'
    },
    {
      header: 'Banco Propio',
      accessorKey: 'mybank',
      columnType: 'text'
    },
    {
      header: 'Cuenta Bancaria',
      accessorKey: 'accountBank',
      columnType: 'text'
    },
    {
      header: 'Canal de Distribucion',
      accessorKey: 'distributionChanel',
      columnType: 'text'
    },
    {
      header: 'Sector',
      accessorKey: 'sectoring',
      columnType: 'text'
    },
    {
      header: 'Organizacion de Ventas',
      accessorKey: 'sellOrganization',
      columnType: 'text'
    },
    {
      header: 'Grupo de vendedores',
      accessorKey: 'groupSeller',
      columnType: 'text'
    },
    {
      header: 'Oficina de Ventas',
      accessorKey: 'salesOffice',
      columnType: 'text'
    },
    {
      header: 'Material',
      accessorKey: 'materiales',
      columnType: 'text'
    },
    {
      header: 'Indicador CME',
      accessorKey: 'cmeIndicator',
      columnType: 'text'
    }
  ]
};

export default ApprovedProvisionEntity;
